export const IconArrowButton = () => {
  return (
    <svg
      width="106"
      height="106"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_88_10376)">
        <circle cx="53" cy="37" r="32" fill="black" />
      </g>
      <g clipPath="url(#clip0_88_10376)">
        <path
          d="M52.9998 26.3333L54.8798 28.2133L47.4398 35.6666H63.6665V38.3333H47.4398L54.8798 45.7866L52.9998 47.6666L42.3332 36.9999L52.9998 26.3333Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_88_10376"
          x="0"
          y="0"
          width="106"
          height="106"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="16" />
          <feGaussianBlur stdDeviation="10.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_88_10376"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_88_10376"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_88_10376">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="matrix(-1 0 0 1 69 21)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
