import Link from "next/link";

export const CategoryCard = ({ title, image, link }) => {
  return (
    <Link href={`/category/${link}`}>
      <a
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: `cover`,
          backgroundPosition: `center`,
          textShadow: `1px 1px #333`,
        }}
        className={`block cursor-pointer bg-black w-full h-[157px] lg:h-[233px] p-8`}
      >
        <h3
          className={`font-display text-[24px] text-white font-bold uppercase`}
        >
          {title}
        </h3>
      </a>
    </Link>
  );
};
