import React from "react";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { page_getStaticProps } from "../lib/statics";
import Link from "next/link";
import { IconArrowButton } from "../components/icon-arrowbutton";
import { ProductCard } from "../components/product-card";
import { CategoryCard } from "../components/home/category-card";
import Image from "../components/image";

config.autoAddCss = false;

const Home = ({ categories, page }) => {
  const seo = {
    metaTitle: `Welcome`,
    metaDescription: `The largest manufacturer of custom tie-down straps in the United States.`,
  };
  const otherProducts = page.attributes.other_products?.products?.data;
  const topSellers = page.attributes.top_sellers?.products?.data;
  const categoryCards = page.attributes.featured_categories;
  categories = categories
    ? categories
        .filter((c) => c?.attributes?.parents?.data?.length === 0)
        .sort((a, b) => {
          return a.attributes.order && b.attributes.order
            ? a.attributes.order - b.attributes.order
            : 1;
        })
    : [];

  const refTopProductsScroller = React.useRef(null);

  const handleScrollClick = (e, direction) => {
    e.preventDefault();
    switch (direction) {
      case "left":
        refTopProductsScroller.current.scrollLeft -= 356;
        break;
      case "right":
        refTopProductsScroller.current.scrollLeft += 356;
        break;
    }
  };

  return (
    <Layout categories={categories}>
      <Seo seo={seo} />
      <div className={`hidden lg:block`}>
        <div className={`py-8 bg-[#F5F5F5] shadow-inner font-display`}>
          <div className={`container relative`}>
            <div
              className={`hidden w-[185px] h-[37px] xl:block absolute left-0`}
              style={{
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: `url("https://ratchet-depot-uploads.s3.amazonaws.com/Group_037c625fb9.svg?updated_at=2022-10-14T13:47:59.358Z")`,
              }}
            ></div>
            <div className={`flex flex-row items-center px-16 justify-center`}>
              <h3
                className={`font-display font-medium text-[20px] leading-none mr-8`}
              >
                The largest manufacturer of custom tie-down straps in the United
                States.
              </h3>
              <span>
                <Link href={`/contact`}>
                  <button
                    className={`bg-[#F5F5F5] text-[#D22628] border border-[#D22628] rounded-full leading-none px-8 py-3 uppercase font-bold hover:bg-[#D22628] hover:text-white`}
                  >
                    BECOME A DISTRIBUTOR
                  </button>
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={`pt-6 lg:pt-28 lg:px-20 bg-white`}>
        <div className="container mx-auto pl-1 pr-1 relative spinfree font-display">
          <div className={``}>
            <h1
              className={`font-bold text-[64px] leading-none relative z-[1] lg:pr-24 `}
            >
              Manufacturing Top Quality Tie-down Products That Outperform The
              Competition
            </h1>
          </div>
          <div
            className={`mt-12 lg:mt-32 flex flex-row items-center leading-none`}
          >
            <h3 className={`text-[32px] uppercase font-bold`}>
              Featured Products
            </h3>
          </div>
        </div>

        <div className={`container mt-8 border-t`}>
          <div className={`lg:grid lg:grid-cols-2 lg:mt-8 `}>
            <div className="bg-white flex flex-col items-center justify-center lg:min-h-[400px]">
              <div className={`block w-full h-full`}>
                <Image
                  alt={""}
                  image={page.attributes.featured_products[0]?.image}
                />
              </div>
            </div>
            <div className={`bg-[#201E50] py-12 lg:py-28 px-12`}>
              <h2
                className={`font-display text-white text-[36px] font-bold leading-10 overflow-hidden`}
              >
                {page.attributes.featured_products[0]?.hero}
              </h2>
              <h3
                className={`font-display text-white text-[28px] font-bold mt-36 overflow-hidden`}
              >
                {page.attributes.featured_products[0]?.title}
              </h3>
              <p className={`font-display text-white mt-4 overflow-hidden`}>
                {page.attributes.featured_products[0]?.content}
              </p>
              <p className={`font-display text-white mt-8`}>Call for pricing</p>
              <div className={`mt-16`}>
                <Link href={page.attributes.featured_products[0]?.uri || ""}>
                  <a
                    className={`text-black bg-white font-display border border-white py-2 px-4 rounded-full border-2 hover:text-white hover:bg-transparent uppercase font-bold`}
                  >
                    Learn More
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className={`block lg:hidden w-full h-full`}>
            <Image
              alt={""}
              image={page.attributes.featured_products[1]?.image}
            />
          </div>
          <div className={`lg:grid lg:grid-cols-2`}>
            <div className={`bg-[#201E50] py-28 px-12`}>
              <h2
                className={`font-display text-white text-[36px] font-bold leading-10`}
              >
                {page.attributes.featured_products[1]?.hero}
              </h2>
              <h3
                className={`font-display text-white text-[28px] font-bold mt-36`}
              >
                {page.attributes.featured_products[1]?.title}
              </h3>
              <p className={`font-display text-white mt-4`}>
                {page.attributes.featured_products[1]?.content}
              </p>
              <p className={`font-display text-white mt-8`}>Call for pricing</p>
              <div className={`mt-16`}>
                <Link href={page.attributes.featured_products[1]?.uri || ""}>
                  <a
                    className={`text-black bg-white font-display border border-white py-2 px-4 rounded-full border-2 hover:text-white hover:bg-transparent uppercase font-bold`}
                  >
                    Learn More
                  </a>
                </Link>
              </div>
            </div>
            <div className={`hidden lg:block w-full h-full`}>
              <Image
                alt={""}
                image={page.attributes.featured_products[1]?.image}
              />
            </div>
          </div>
        </div>
        <div className={`hidden lg:block`}>
          <section className={`container grid grid-cols-3 gap-8 mt-16`}>
            {otherProducts?.map((product, key) => {
              return <ProductCard key={key} product={product} />;
            })}
          </section>
        </div>
        <section className={`container px-1 mt-32 leading-none`}>
          <h3
            className={`text-[32px] uppercase font-bold leading-none border-b pb-8 font-display`}
          >
            CATEGORIES
          </h3>
          <div className={`grid lg:grid-cols-2 lg:mt-8 gap-4`}>
            {categoryCards.map((card, key) => {
              return (
                <CategoryCard
                  key={key}
                  image={card?.image?.data?.attributes?.url}
                  title={card?.title}
                  link={card?.category?.data?.attributes?.slug}
                />
              );
            })}
          </div>
        </section>

        <section
          className={`container px-1 mt-32 leading-none font-display bg-white pb-8`}
        >
          <h3
            className={`text-[32px] uppercase font-bold leading-none border-b pb-8`}
          >
            Top Sellers
          </h3>
        </section>
      </div>

      <div className={`overflow-x-hidden relative`}>
        <span className={`absolute z-10 top-[30%] left-0`}>
          <Link href={``}>
            <a onClick={(event) => handleScrollClick(event, "left")}>
              <IconArrowButton />
            </a>
          </Link>
        </span>
        <div
          ref={refTopProductsScroller}
          id={"topProductsScroller"}
          className={`flex flex-row bg-white lg:pl-20 overflow-x-scroll relative items-center`}
        >
          {topSellers.map((product, key) => (
            <div
              key={key}
              className={`bg-gray pb-8 px-6 max-w-[356px] min-w-[356px]`}
            >
              <ProductCard
                className={`bg-gray pb-8 px-3 w-full`}
                product={product}
              />
            </div>
          ))}
        </div>
        <span className={`absolute z-10 top-[30%] right-0 scale-x-[-1]`}>
          <Link href={``}>
            <a onClick={(event) => handleScrollClick(event, "right")}>
              <IconArrowButton />
            </a>
          </Link>
        </span>
      </div>
    </Layout>
  );
};

export async function getStaticProps() {
  return page_getStaticProps("homepage");
}

export default Home;
