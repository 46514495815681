import React from "react";
import Link from "next/link";
import Image from "./image";

const image = {
  data: {
    attributes: {
      url: "https://ratchet-depot-uploads.s3.amazonaws.com/product_f72ce0dd30.jpg?updated_at=2022-10-14T15:23:31.563Z",
    },
  },
};

export const ProductCard = ({ product = undefined }) => {
  return (
    <article className={`bg-white min-h-[350px]`}>
      <Link href={`/product/${product.id}`}>
        <div className={`flex flex-col hover:shadow-lg cursor-pointer`}>
          <div className="block items-center justify-center overflow-hidden ">
            <Image
              alt={product.attributes.name}
              image={product.attributes.image}
            />
          </div>
          <div className={`p-6`}>
            <h1
              className={`font-display font-bold text-[28px] leading-none block`}
            >
              {product?.attributes?.name}
            </h1>
            <p className={`font-display text-[16px] mt-4`}>Call for pricing</p>
          </div>
        </div>
      </Link>
    </article>
  );
};
