import { getStrapiMedia } from "../lib/media";
import NextImage from "next/image";

const Image = ({ image, style }) => {
  if (!image?.data?.id)
    return <div style={{ width: "308px", height: "230px" }}></div>;
  const { url, alternativeText, width, height } = image.data.attributes;

  const loader = () => {
    return getStrapiMedia(image);
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        minHeight: "300px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "absolute",
          overflow: "hidden",
        }}
      >
        <div>
          <NextImage
            loader={loader}
            layout="responsive"
            width={"100%"}
            height={"100%"}
            objectFit={style?.objectFit || "cover"}
            src={getStrapiMedia(image)}
            alt={alternativeText || ""}
            objectPosition={style?.objectPosition || "top center"}
          />
        </div>
      </div>
    </div>
  );
};

export default Image;
